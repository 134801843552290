import React from "react";
import PlatformTopBanner from "../top_banner/platform_top_banner";
import GetLatest from "../latest/latest";
import FeaturedSingleBlog from "../featured/featuredSingle";
import PrivacyFeatures from "../features/privacyFeatures";
import SuccessRoad from "../get_started/success_road";
import SingleReview from "../reviews/singleReview";
import Subscribe2 from "../subscribe/subscribe_2";
import Value from "../value/value_scalability";
import { useTranslation } from 'react-i18next';

const PlatformScalability = () => {
  const {t} = useTranslation();
  const scalabiltyData = [
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/girl_with_laptop_in_lap.svg",
      title: `${t('dont_set_any_limits_for_yourself')}`,
      description:
        `${t('at_Mereos_we_support_companies')}`,
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/man_and_women_walking_with_shadows.svg",
      title: `${t('manage_peak_traffic_periods')}`,
      description:
        `${t('in_2021_we_tested_the_capacity_of_our')}`,
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/jar_with_coins_inside.svg",
      title: `${t('control_your_costs')}`,
      description:
        `${t('with_the_pay_as_you_go')}`,
    },
  ];

  const values = [
    {
      title: `${t('the_power_of_the_cloud')}`,
      description: `${t('we_use_multi_region_aws_hosting')}`,
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/dart_icon_with_bg.svg"
    },
    {
      title: `${t('data_encryption')}`,
      description: `${t('data_confidentiality_is_ensured_by')}`,
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/tick_icon_with_bg.svg"
    },
    {
      title: `${t('an_agile_model')}`,
      description: `${t('we_continually_optimize_the_efficiency')}`,
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/crown_icon_with_bg.svg"
    }
  ];

  return (
    <>
      <div className={`conatiner font-sans`}>
        <PlatformTopBanner
          pageType={"left"}
          page={"scalabilite"}
          title={t('schedule_up_to_20000_simultaneous_sessions')}
          description={t('whether_youre_a_large_company_or_simply_ambitious')}
          imageSrc={"https://d2lxkizvrhu4im.cloudfront.net/images/platform_top_banner_faces_on_board.svg"}
        />

        <img
          alt="right star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          className="absolute left-2 hidden md:block self-end"
        />

        {/* sub Container */}
        <div className={`mx-auto w-11/12 lg:w-10/12`}>
          <div className="my-20">
            <Value
              values={values}
            />
          </div>

          <div className="md:my-20">
            <SuccessRoad
              title={t("maximize_your_growth_minimize_your_costs")}
              Data={scalabiltyData}
              page="scalbility"
              stylePage="scalabilite"
            />
          </div>
        </div>

        <div className="relative">
          <div className="absolute bg-yellow-25 w-full  md:-bottom-1  md:h-12 z-0"></div>
          <SingleReview
            page={"scalabilite"}
            imgSrc="https://d2lxkizvrhu4im.cloudfront.net/images/juan.png"
            title={t('meet_our_experts')}
            description={t('at_mereos_we_have_deployed_an_ultra_scalable')}
            reviewer="Juan Pablo Olivera"
            reviewer_designation="DevOps Engineer"
          />
        </div>

        <div className="bg-yellow-25">
          <Subscribe2 />
        </div>
      </div>
    </>
  );
};

export default PlatformScalability;
