import * as React from "react";

import Layout from "../components/layout/layout";
import PlatformScalabilityComponent from "../components/platform/platform_scalability";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const PlatformScalability = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title={t('scalability')}
        description={t('organize_large_scale_recruitment')}
      />
      <Layout>
        <PlatformScalabilityComponent />
      </Layout>
    </>
  )
}

export default PlatformScalability
