import React from "react";

const Value = ({
  title,
  subTitle,
  values,
  leftStar,
  rightStar
}) => {
  return (
    <div className="font-sans flex flex-col mx-auto w-full space-y-10 md:space-y-20">
      {
        leftStar && 
        <img
          alt="right star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          className="absolute left-2 hidden md:block self-end"
        />
      }
      {
        rightStar && 
        <img
          alt="right star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          className="absolute right-2 hidden md:block self-end"
        />
      }

      <div className="space-y-2 md:space-y-8">
        {
          title &&
          <div className={`text-3xl md:text-5xl font-semibold w-full  text-center mx-auto md:w-5/6`}>
            {title}
          </div>
        }
        {
          subTitle &&
          <div className={`font-medium text-sm md:text-xl  text-center mx-auto w-10/12 md:w-full text-gray-500`}>
            {subTitle}
          </div>
        }
      </div>
      <div className="flex flex-col md:flex-row justify-between w-full space-y-10 md:space-y-0">
        {
          values.map((value, index) => (
            <div className=" flex flex-col w-full mx-auto md:mx-0 space-y-3  md:w-1/3">
              <div className="mx-auto">
                <img
                  alt="values img"
                  src={value.icon}
                  className="m-0"
                />
              </div>
              <div className="flex flex-col w-full text-center  space-y-2">
                <div className=" text-lg md:text-xl font-semibold w-full  text-center">
                  {value.title}
                </div>
                <div className="text-sm md:text-base text-gray-400  w-4/5 text-center mx-auto ">
                  {value.description}
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
};

export default Value;
